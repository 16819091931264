import { ScrollCaptain } from 'scroll-captain';
import './facts.scss';

class Facts {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-facts',
            itemValue: 'item',
            breakpoint: 1023
        };

        // Merge settings
        this.settings = Object.assign({}, defaults, options);
        
        // DOM Elements
        this.$root = element;
        this.$$facts = this.$root.querySelectorAll(`[${this.settings.initAttr}^="${this.settings.itemValue}"]`);

        // Options
        this.animation = null;
        this.breakpoint = window.matchMedia(`(max-width: ${this.settings.breakpoint}px)`);
        this.breakpointActive = this.breakpoint.matches;
    }

    initialize () { 
        this.loadAnimation();
        this.setEvents();
    }

    // Configure and load animations with ScrollCaptain
    loadAnimation () {
        let animationOptions = {
            initAttr: this.settings.initAttr,
            updateOnResize: () => {
                if (this.breakpoint.matches !== this.breakpointActive) {
                    this.breakpointActive = this.breakpoint.matches;
                    this.updateAnimation(false);
                }
            }
        };

        // Set up individual animations for each fact
        this.$$facts.forEach(($fact, index) => {
            const key = `animateTile${index + 1}`;            

            animationOptions[key] = {
                targetSuffix: `${this.settings.itemValue}-${index + 1}`,
                onscroll: true,
                translateX: this.breakpoint.matches || window.reducedMotionActive ? null : { 0: 150 * (index + 1), 50: 0, 100: 0 }
            };
        });        

        this.animation = new ScrollCaptain(this.$root, animationOptions);        
    }

    updateAnimation (updateRequired) {                
        // Set up individual animations for each fact
        this.$$facts.forEach(($fact, index) => {
            const key = `animateTile${index + 1}`;        
            this.animation.defaultOptions[key].translateX = this.breakpoint.matches || window.reducedMotionActive ? null : { 0: 150 * (index + 1), 50: 0, 100: 0 };
        });        

        if (updateRequired) {
            this.animation.update();
        }
    }

    setEvents () {
        window.addEventListener('motionPreferenceChanged', () => {
            this.updateAnimation(true);
        });
    }
}

export { Facts };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-facts="root"]');
        $$roots.forEach($root => {
            const $rootAPI = new Facts($root);
            $rootAPI.initialize();
        });
    }
});
